



































  import { PAGE_CONTENT_TYPE } from '@/constant/Enums';
  import { ConfigModel, ContentModel } from '@/store/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { State } from 'vuex-class';

  @Component({
    name: 'Help',
    components: {
      informationForm: () => import('@/components/informationForm.vue'),
      helpForm: () => import('@/components/helpForm.vue'),
      protectNow: () => import('@/components/ProtectNow.vue'),
    },
    mixins: [],
  })
  export default class Help extends Vue {
    @State('configs') configs!: ConfigModel[];
    @State('page_content') page_content!: ContentModel[];

    public get banner(): string {
      return this.page_content?.filter((item) => item.type === PAGE_CONTENT_TYPE.BANTUAN)[0]?.image ?? require('@/assets/images/banner/partner.jpg');
    }

    public get step(): string[] {
      return this.page_content?.filter((item) => item.type === PAGE_CONTENT_TYPE.BANTUAN_STEP).map((item) => item.image);
    }

    public get contactInfo(): string {
      return this.configs.filter((item) => item.sys_config_id === '1')[0]?.value2.replace('h3', 'h5');
    }

    public get helpStep() {
      return [
        {
          title: 'Insurance System',
          image: this.step[0] ?? '/img/help/help-step-1.png',
        },
        {
          title: 'RajaPremi.com Help Center',
          image: this.step[1] ?? '/img/help/help-step-2.png',
        },
        {
          title: 'Costumer',
          image: this.step[2] ?? '/img/help/help-step-3.png',
        },
      ];
    }

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }
  }
